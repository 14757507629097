import clsx from 'clsx';
import { InputHTMLAttributes, ChangeEvent } from 'react';

interface InputProps {
  label: string;
  value: InputHTMLAttributes<HTMLInputElement>['value'];
  type: InputHTMLAttributes<HTMLInputElement>['type'];
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: () => void;
  placeholder?: string;
  isError?: boolean;
}

function Input({
  label,
  value,
  type,
  handleChange,
  onBlur = () => {},
  placeholder,
  isError = false,
}: InputProps) {
  return (
    <div>
      <label className='label'>
        <span className='text-sm font-gtflexa'>{label}</span>
      </label>
      <input
        type={type}
        placeholder={placeholder}
        className={
          'input input-bordered w-full rounded-full border-black border-2 font-gtflexa' +
          clsx(isError ? 'input-error' : '')
        }
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
      />
    </div>
  );
}

export default Input;
