import { Dispatch, SetStateAction, useState } from 'react';
import clsx from 'clsx';
import { SIGNUP_LOGIN_ERROR_MESSAGES } from '@/utils/constants';
import Input from '@/components/Input';
import Button from '@/components/Button';
import InvalidInputWarning from '@/components/InvalidInputWarning';
import { useRouter } from 'next/router';
import { StaticRoutes } from '@/utils/links';
import { useAuthContext } from '@/contexts/AuthContext';
import { SignupLoginError } from '@/lib/mongoRealm/mongoRealm';
import { Divider } from 'react-daisyui';

function LogInView() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isAttemptingLogin, setIsAttemptingLogin] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [, { logInUser }] = useAuthContext();

  const router = useRouter();

  return (
    <form
      className='h-full w-full flex flex-col justify-between text-center p-6 relative'
      onSubmit={(e) => {
        // e.preventDefault() disables default form action of page refresh
        e.preventDefault();
        handleLogIn(email, password, setErrorMessage, setIsAttemptingLogin, router, logInUser);
      }}
    >
      <span className='mt-12' />
      <h1 className='text-left font-bold text-5xl sm:text-5xl py-10 z-10'>
        WELCOME BACK to Greenworkx Learning!
      </h1>

      <span className='grow' />
      <img
        src='/illustrations/waving_hand.svg'
        alt='Illustration of a waving hand and a star'
        className='absolute top-24 -right-20 sm:-top-5  sm:-right-10 z-0 h-96 w-96'
      />

      <h2 className='font-bold text-3xl sm:text-3xl py-10 z-10'>Looking for a job?</h2>
      <Button
        className='btn-primary rounded-full font-bold border-solid border-2 border-secondary w-full'
        href='https://careers.greenworkx.org'
      >
        Explore your green career options!
      </Button>

      <Divider className='py-10' />

      <h2 className='font-bold text-3xl sm:text-3xl py-10 z-10'>Here to study?</h2>

      <div className='z-20'>
        <Input
          {...{
            label: 'EMAIL ADDRESS',
            value: email,
            type: 'email',
            handleChange: (e) => {
              setEmail(e.target.value);
            },
            placeholder: 'myemail@me.com',
          }}
        />
        <div className='mt-4' />
        <Input
          {...{
            label: 'PASSWORD',
            value: password,
            type: 'password',
            handleChange: (e) => {
              setPassword(e.target.value);
            },
            placeholder: 'Your password',
          }}
        />
      </div>

      {/* Render invalid email or password alert if error message exists */}
      {errorMessage ? <InvalidInputWarning message={errorMessage} /> : <span className='grow' />}

      <div className={clsx(errorMessage ? 'mt-4' : 'mt-16')}>
        <div className='italic hover:underline'>
          <a href={StaticRoutes.ResetPassword}>Forgot your password? Reset here</a>
        </div>
        <div className='mt-4' />
        <Button
          className='btn-primary rounded-full font-bold border-solid border-2 border-secondary w-full'
          type='submit'
          isLoading={isAttemptingLogin}
        >
          Log in
        </Button>
        <div className='mt-4' />
        <Button
          className='btn-ghost rounded-full border-2 font-bold w-full'
          onClick={() => handleCreateAccountNavigation(router)}
        >
          Create an account
        </Button>
      </div>
    </form>
  );
}

const handleCreateAccountNavigation = (router: ReturnType<typeof useRouter>) => {
  // navigates the learner to the welcome page
  router.push(StaticRoutes.Welcome);
};

const handleLogIn = async (
  email: string,
  password: string,
  setErrorMessage: Dispatch<SetStateAction<string>>,
  setIsAttemptingLogin: Dispatch<SetStateAction<boolean>>,
  router: ReturnType<typeof useRouter>,
  logInUser: (email: string, password: string) => Promise<SignupLoginError | string>,
) => {
  if (email && password) {
    setIsAttemptingLogin(true);
    setErrorMessage('');

    // If email and password are both not empty, attempt to log in
    const result = await logInUser(email, password);

    if (typeof result !== 'string' && Object.hasOwn(result, 'error')) {
      const errorMessage = (result as SignupLoginError).error;
      setErrorMessage(errorMessage);
      setIsAttemptingLogin(false);
      return;
    } else {
      router.push(StaticRoutes.Home);
    }
  }

  // if email and password are both empty, set error message to the empty email and password error message
  if (!email && !password) {
    setErrorMessage(SIGNUP_LOGIN_ERROR_MESSAGES.EMPTY_EMAIL_AND_PASSWORD);
    return;
  }

  // if only email is empty, set error message to the empty email error message
  if (!email) {
    setErrorMessage(SIGNUP_LOGIN_ERROR_MESSAGES.EMPTY_EMAIL);
    return;
  }

  // if only password is empty, set error message to the empty password error message
  if (!password) {
    setErrorMessage(SIGNUP_LOGIN_ERROR_MESSAGES.EMPTY_PASSWORD);
  }
};

export default LogInView;
